import { FC, useEffect } from "react";

import { NavLink, useLocation } from "react-router-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { LiveError, LivePreview, LiveProvider } from "react-live";
import { Warning } from "../../components";
import { useFetch, usePreviewMode, useRenderPath } from "../../hooks";
import { copyToClipboard, templatesApiUrl } from "../../utils";

export const RecoveryCodes: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { state } = useLocation() as any;
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const alias = pathname.split("/")[2];

  const {
    apiCall: getRecoveryCodesTemplate,
    response: recoveryCodesTemplate,
    loading: recoveryCodesTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getRecoveryCodesTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recoveryCodes = state?.codes;

  const copyRecoveryCodes = () => {
    copyToClipboard(recoveryCodes, t("2fa.recoveryCodesGeneratedSuccessfully"));
  };

  const scope = {
    NavLink,
    renderPath,
    t,
    Warning,
    recoveryCodes,
    Icon,
    copyRecoveryCodes,
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("2fa.genereate2faRecoveryCodes")}</title>
        <style>{recoveryCodesTemplate?.css}</style>
      </Helmet>

      {!recoveryCodesTemplateLoading && (
        <LiveProvider
          code={recoveryCodesTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
