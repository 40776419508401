import {
  FC,
  ReactElement,
  cloneElement,
  isValidElement,
  ReactNode,
} from "react";
import { ButtonProps } from "../../components/button";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { cx } from "../../utils";

interface HeaderProps {
  logo?: ReactElement;
  buttonProps?: ButtonProps;
  headerClass?: string;
  children?: ReactNode;
}

export const Header: FC<HeaderProps> = ({
  children,
  headerClass = "",
  buttonProps,
  logo,
}) => {
  return (
    <header
      className={cx([
        "flex items-center md:pr-6 pr-2 py-3 shadow-md bg-white w-full",
        headerClass,
      ])}
    >
      <div className="flex md:w-72 mr-6">
        {buttonProps && (
          <button
            type="button"
            {...buttonProps}
            className={cx([buttonProps.className, "mr-2 p-2 md:hidden"])}
          >
            {buttonProps.children ? buttonProps.children : <Icon name="Menu" />}
          </button>
        )}
        {isValidElement(logo) && cloneElement(logo)}
      </div>
      {children && children}
    </header>
  );
};
