import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink, useLocation } from "react-router-dom";
import { MainContainer } from "../../containers";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { useFetch, usePreviewMode, useRenderPath } from "../../hooks";
import { templatesApiUrl } from "../../utils";

export const Personalization: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);
  const alias = pathname.slice(1);

  const {
    apiCall: getPersonalizationTemplate,
    response: personalizationTemplate,
    loading: personalizationTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getPersonalizationTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scope = { t, NavLink, Icon, renderPath };

  return (
    <>
      <Helmet>
        <title>{t<string>("personalization.dataPersonalisation")}</title>
        <style>{personalizationTemplate?.css}</style>
      </Helmet>

      <MainContainer>
        {!personalizationTemplateLoading && (
          <LiveProvider
            code={personalizationTemplate?.html || "<></>"}
            scope={scope}
          >
            <LivePreview />
            <LiveError />
          </LiveProvider>
        )}
      </MainContainer>
    </>
  );
};
