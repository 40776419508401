import { FC, MouseEvent, ReactNode } from "react";

import { createPortal } from "react-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { useTranslation } from "react-i18next";

import {
  cancelBtnClasses,
  cx,
  primaryBtnClasses,
  warningBtnClasses,
} from "../../utils";
import { BtnSize, BtnStyle, BtnType, Button } from "../button";
import { ModalFooter } from "./ModalFooter";

export enum ModalSize {
  sm = "max-w-sm",
  md = "max-w-xl",
  lg = "max-w-3xl",
  xl = "max-w-6xl",
  unset = "max-w-full",
}

export interface IModalProps {
  visible: boolean;
  children: ReactNode;
  noCloseButton?: boolean;
  modalSize?: ModalSize;
  title?: string;
  titleDescription?: string;
  headerClassName?: string;
  withFooter?: boolean;
  noHeader?: boolean;
  onCancel?: () => void;
  hide?: () => void;
  onConfirmClick?: () => void;
  // onFormSubmit?: (data: any) => void;
  onFormSubmit?: any;
  onCloseClick?: () => void;
  disabled?: boolean;
  confirmBtnText?: string;
  warningModal?: boolean;
  blockOutsideClick?: boolean;
  infoModal?: boolean;
  confirmBtnType?: "submit" | "button";
}

const doNothing = () => {
  return;
};

export const Modal: FC<IModalProps> = (props) => {
  const { t } = useTranslation();
  return props.visible
    ? createPortal(
        <>
          <div
            onClick={!props.blockOutsideClick ? props.hide : doNothing}
            className={cx([
              "fixed top-0 left-0 py-5 sm:px-0 px-4",
              "w-full h-full overflow-auto bg-primary-secText bg-opacity-40",
              "animate-fade-in z-10",
            ])}
          >
            <div className="flex items-center justify-center min-h-full">
              <div
                className={cx([
                  "w-full relative bg-white overflow-y-auto rounded",
                  props.modalSize || ModalSize.md,
                  !ModalSize.unset && "m-5 rounded",
                ])}
                onClick={(event: MouseEvent<HTMLDivElement>): void =>
                  event.stopPropagation()
                }
              >
                {!props.noCloseButton && (
                  <button
                    onClick={props.onCloseClick || props.hide}
                    className={cx([
                      "p-1 self-start rounded transition",
                      "focus:outline-none text-primary-mainText hover:bg-gray-50 hover:text-primary focus:shadow-outlinePrimary active:shadow-outlinePrimary",
                      "absolute top-3.5 right-3",
                    ])}
                    type="button"
                  >
                    <Icon name="Close" size={24} />
                  </button>
                )}

                <div>
                  {!props.noHeader && (
                    <div
                      className={cx([
                        "p-4 border-b border-primary-stroke pr-14",
                        props.headerClassName,
                      ])}
                    >
                      <h5 className="text-primary-mainText">{props.title}</h5>
                      {props.titleDescription && (
                        <p className="text-sm mb-0 mt-1 text-primary-tertText">
                          {props.titleDescription}
                        </p>
                      )}
                    </div>
                  )}

                  {!props.withFooter && (
                    <div className="sm:py-2 pt-4 sm:pt-2 px-4">
                      {props.children}
                    </div>
                  )}

                  {props.withFooter && (
                    <form onSubmit={props.onFormSubmit}>
                      <div className="sm:py-2 pt-4 sm:pt-2 px-4">
                        {props.children}
                      </div>

                      <ModalFooter>
                        <Button
                          btnType={BtnType.secondary}
                          btnStyle={BtnStyle.neutral}
                          btnSize={BtnSize.nomral}
                          className={cancelBtnClasses}
                          type="button"
                          onClick={props.onCancel || props.hide}
                        >
                          {t<string>("2fa/textMessage.cancel")}
                        </Button>

                        {!props.infoModal && (
                          <Button
                            type={props.confirmBtnType || "button"}
                            onClick={props.onConfirmClick}
                            btnStyle={
                              props.warningModal
                                ? BtnStyle.warning
                                : BtnStyle.primary
                            }
                            btnSize={BtnSize.nomral}
                            className={cx([
                              "ml-4 flex items-center",
                              props.warningModal
                                ? warningBtnClasses
                                : primaryBtnClasses,
                            ])}
                            disabled={props.disabled}
                          >
                            {props.disabled && (
                              <img
                                src="/images/oval.svg"
                                alt="Spinner"
                                width={16}
                                className="mr-2"
                              />
                            )}

                            {props.confirmBtnText}
                          </Button>
                        )}
                      </ModalFooter>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;
};
