import { getUserInfo } from "./web-storage-controller";

export enum Permission {
  admin = "SuperAdmin",
  usersEditor = "EditorUsers",
  appsEditor = "EditorSpecificApps",
  betaTester = "BetaTester",
}

export const isUserRole = (userRole: Permission) => {
  const {
    profile: { role },
  } = getUserInfo();

  const rolesArray = Array.isArray(role) ? role : [role];
  const isThisRole = rolesArray?.some(
    (item) => item?.toLowerCase() === userRole?.toLowerCase()
  );

  return isThisRole;
};
