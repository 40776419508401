import { FC, useEffect } from "react";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { useTfaContext } from "../../context/TfaContext";
import {
  useFetch,
  usePreviewMode,
  useRenderPath,
  useToggle,
} from "../../hooks";

import {
  templatesApiUrl,
  TFA_AUTH_APP_PATH,
  TFA_EMAIL_PATH,
  TFA_RECOVERY_CODES_PATH,
  TFA_TEXT_MESSAGE_PATH,
} from "../../utils";

export interface IAuth {
  emailOtpEnabled: boolean;
  hasAuthenticator: boolean;
  is2faEnabled: boolean;
  isMachineRemembered: boolean;
  recoveryCodesLeft: number;
  smsOtpEnabled: boolean;
  status: number;
}

export const TwoFactorAuthentication: FC = () => {
  const { tfa } = useTfaContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toggle, visible } = useToggle();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);
  const { pathname } = useLocation();

  const alias = pathname.replace("/", "");

  const {
    apiCall: getTwoFATemplate,
    response: twoFATemplate,
    loading: twoFATemplateLoading,
  } = useFetch("get");

  const {
    apiCall: getMfaSettings,
    response: mfaSettings,
    loading,
  } = useFetch("get");

  const { apiCall: getRecoveryCodes, loading: recoveryCodesLoading } =
    useFetch("post");

  const { authenticator2faEnabled, email2faEnabled, sms2faEnabled } =
    mfaSettings || {
      authenticator2faEnabled: false,
      email2faEnabled: false,
      sms2faEnabled: false,
    };

  const {
    hasAuthenticator,
    smsOtpEnabled,
    emailOtpEnabled,
    emailUsed,
    phoneUsed,
    recoveryCodesLeft,
  } = tfa;

  const hideEmail = (email: string) => {
    return email.replace(/(.{1})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < 7; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  };

  useEffect(() => {
    getMfaSettings("Users/GetMfaSettings");
    getTwoFATemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetRecoveryCodes = () => {
    getRecoveryCodes("ManageApi/GenerateRecoveryCodes", {}, (response) => {
      navigate(TFA_RECOVERY_CODES_PATH, {
        state: {
          codes: response,
        },
      });
    });
  };

  const mfasDisabled =
    smsOtpEnabled === false &&
    emailOtpEnabled === false &&
    hasAuthenticator === false;

  const tfaMethods = [
    {
      path: TFA_TEXT_MESSAGE_PATH,
      title: t("2fa.textMessage"),
      message: smsOtpEnabled
        ? `${t("2fa.phoneNumber")} (${phoneUsed && phoneUsed}) ${t(
            "2fa.usedForVerification"
          )}`
        : t("2fa.notSetupYet"),
      indicator: smsOtpEnabled,
      visible: sms2faEnabled,
    },
    {
      path: TFA_EMAIL_PATH,
      title: t("2fa.emailVerification"),
      message: emailOtpEnabled
        ? `${t("2fa.emailAddress")}  (${
            !!emailUsed && hideEmail(emailUsed)
          }) ${t("2fa.usedForVerification")}`
        : t("2fa.notSetupYet"),
      indicator: emailOtpEnabled,
      visible: email2faEnabled,
    },
    {
      path: TFA_AUTH_APP_PATH,
      title: t("2fa.authenticatorApp"),
      message: hasAuthenticator
        ? t("2fa.authenticatorUsedForVerification")
        : t("2fa.notSetupYet"),
      indicator: hasAuthenticator,
      visible: authenticator2faEnabled,
    },
  ];

  const scope = {
    tfaMethods,
    mfasDisabled,
    resetRecoveryCodes,
    recoveryCodesLeft,
    loading,
    renderPath,
    toggle,
    visible,
    t,
    Icon,
    NavLink,
    recoveryCodesLoading,
  };

  return (
    <>
      <Helmet>
        <title>{t("2fa.twoFactorAuthenticationTitle")}</title>
        <style>{twoFATemplate?.css}</style>
      </Helmet>

      {!twoFATemplateLoading && (
        <LiveProvider code={twoFATemplate?.html || "<></>"} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
