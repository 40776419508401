import { useEffect, useState } from "react";

import { bskPush, eventPageView } from "../../../utils/analytics";
import { getUserInfo } from "../../../utils/web-storage-controller";

export const BiskoScript = () => {
  const Script = (resourceUrl: string) => {
    const {
      access_token,
      profile: { sub: userId },
    } = getUserInfo();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isInit, setInit] = useState(false);

    useEffect(() => {
      if (isInit) eventPageView();
    }, [isInit]);

    useEffect(() => {
      if (!isInit && isLoaded) {
        if (access_token && userId) {
          bskPush("init", "180018", userId);
          setInit(true);
        } else if (!access_token) {
          bskPush("init", "180018");
          setInit(true);
        }
      }
    }, [isLoaded, isInit, access_token]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const script = document.createElement("script");
      script.src = resourceUrl;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        setIsLoaded(true);
      };

      return () => {
        document.body.removeChild(script);
      };
    }, [resourceUrl]);
  };

  return <>{Script("https://bisko.gjirafa.net/web/gjirafatest-sdk.js")}</>;
};
