import { FC, ReactNode } from "react";

import { TermsFooter } from "../ui/TermsFooter";
import { Tab } from "./tabs/Tab";
import { Tabs } from "./tabs/Tabs";
import TermsSidebar from "./terms-sidebar";

interface ITermsContainer {
  children: ReactNode;
}

export const TermsContainer: FC<ITermsContainer> = ({ children }) => {
  return (
    <>
      <div className="flex relative">
        <TermsSidebar
          className="mt-3"
          options={[
            { label: "First Section", sectionId: "first-section" },
            { label: "Second Section", sectionId: "second-section" },
            { label: "Third Section", sectionId: "third-section" },
          ]}
        />

        <section className="flex-1 lg:pl-24 md:pr-6 px-4 sm:px-2">
          <Tabs className="mb-8 sm:mb-20 mt-6 sm:mt-3">
            <Tab path="/privacy" label="Privacy Policy" />
            <Tab path={`/terms`} label="Terms and Conditions" />
          </Tabs>

          <div className="sm:px-8">{children}</div>
        </section>
      </div>

      <TermsFooter />
    </>
  );
};
