import { FC } from "react";
import { cx } from "../../utils";

interface IDivider {
  className?: string;
}

export const Divider: FC<IDivider> = ({ className }) => {
  return <div className={cx(["border-b border-gray-300", className])} />;
};
