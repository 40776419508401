import { FC, useEffect } from "react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

import { useUserContext } from "../../context";
import { useOrganizationSettingsContext } from "../../context/OrganizationSettingsContext";
import { useFetch, useToggle } from "../../hooks";
import { cx, featureEnabled, history, textareaClasses } from "../../utils";
import { Modal, ModalSize } from "../modal";
import { Textarea } from "../textarea";

interface IContactSupport {
  message: string;
}

export const TermsFooter: FC = () => {
  const { hash } = useLocation();
  const { organization } = useOrganizationSettingsContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const { visible, toggle } = useToggle();

  const { apiCall: submitContactRequest, loading } = useFetch("post");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const resetUrlState = () => {
    if (hash === "#contactform") return history.push("/");
  };

  const onContactSupportSubmit: SubmitHandler<any> = (
    data: IContactSupport
  ) => {
    const contactSupportObj = {
      ...data,
      email: user?.email,
    };

    submitContactRequest("Feedback/ContactSupport", contactSupportObj, () => {
      toast.success(t<string>("contactSupport.messageSentSuccess"));
      toggle();
      reset();
      resetUrlState();
    });
  };

  const options = [
    {
      label: t("general.privacyPolicy"),
      path: organization?.privacyPolicyUrl,
    },
    {
      label: t("general.termsConditions"),
      path: organization?.termsConditionsUrl,
    },
    {
      ...(featureEnabled["contactSupport"] === true && {
        label: t("general.contact"),
        path: "#contactform",
      }),
    },
  ];

  useEffect(() => {
    if (hash === "#contactform") {
      toggle();
    }
  }, [hash]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="md:fixed bottom-0 left-0 md:w-72 md:mt-0 mt-auto">
        <ul className="flex px-6 py-4 md:justify-start justify-center flex-wrap bg-white">
          {options.map((option, index) => (
            <li className="mr-4" key={index}>
              <a
                href={option.path}
                className="text-sm text-neutral hover:text-primary hover:underline whitespace-nowrap"
              >
                {" "}
                {option.label}
              </a>
            </li>
          ))}
        </ul>
      </div>

      {visible && (
        <Modal
          visible={visible}
          hide={() => {
            toggle();
            reset();
            resetUrlState();
          }}
          modalSize={ModalSize.md}
          title={t("contactSupport.title")}
          withFooter
          onFormSubmit={handleSubmit(onContactSupportSubmit)}
          confirmBtnText={t("general.send")}
          titleDescription={t("contactSupport.description")}
          disabled={loading}
          blockOutsideClick
          confirmBtnType="submit"
        >
          <div className="my-6">
            <Textarea
              {...register("content", {
                required: `${t("contactSupport.contentFieldRequired")}`,
              })}
              autoFocus
              labelProps={{
                children: (
                  <>
                    {t("contactSupport.contentLabel")}{" "}
                    <span className="text-primary">{user?.email}</span>
                  </>
                ),
                className: "text-neutral block pb-0 mb-1.5",
              }}
              error={!!errors.content && errors.content.message}
              placeholder={t("contactSupport.contentPlaceholder")}
              className={cx(["textarea-form-control", textareaClasses])}
              maxLength={1000}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
