import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { useEffect, useState } from "react";
import { onlyForRoles } from "../../context";
import { useFetch } from "../../hooks";
import {
  cx,
  featureEnabled,
  GIFTS_PATH,
  myAccountDisabled,
  PAYMENTS_PATH,
  Permission,
  personalizationDisabled,
  securityDisabled,
  SUBSCRIPTIONS_PATH,
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS,
} from "../../utils";
import { Calendar, Dashboard, Security, User, Voucher } from "../icons";
import { Sidebar as SidebarLayout } from "../sidebar";

export const Sidebar = () => {
  const { t } = useTranslation();
  const { betaTester } = Permission;
  const { apiCall: getModels, response: models } = useFetch("get");
  const [customRoutes, setCustomRoutes] = useState([
    {
      title: "",
      to: "",
      icon: "",
      id: 0,
    },
  ]);

  useEffect(() => {
    getModels(
      `${USER_PROFILE_API}/api/integration-models/list/published`,
      USER_PROFILE_API_HEADERS
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const routes = models?.data?.map(
      (item: { name: string; alias: string; icon: string; id: number }) => {
        return {
          title: item?.name,
          to: `custom-page/${item?.alias}`,
          icon: (
            <img
              src={
                item?.icon ||
                "https://cdn.iconscout.com/icon/free/png-256/subscription-1742827-1479747.png?f=webp&w=256"
              }
              alt={item?.name}
            />
          ),
          id: item?.id,
        };
      }
    );
    routes && setCustomRoutes(routes);
  }, [models]);

  return (
    <>
      <SidebarLayout
        wrapperClassName="sm:px-4 px-2 top-0"
        renderItem={({ to, title, icon, id }: any) => {
          return (
            <>
              <NavLink
                className={({ isActive }) =>
                  cx([
                    "nav-link flex items-center py-3 sm:px-2 mx-3 sm:mx-0 rounded-md text-sm hover:text-primary transition font-medium sm:focus:shadow-outlineGray focus:outline-none relative",
                    isActive
                      ? "text-primary sm:bg-gray-50 active"
                      : "text-primary-secText",
                  ])
                }
                to={to}
                state={{ modelId: id }}
              >
                {icon ? (
                  <span className="w-5 h-5 mr-4 hidden sm:inline-block">
                    {icon}
                  </span>
                ) : null}
                <span className="whitespace-nowrap sm:whitespace-normal">
                  {title}
                </span>
              </NavLink>
            </>
          );
        }}
        list={[
          ...(!myAccountDisabled
            ? [
                {
                  title: t("sidebar.myAccount"),
                  to: "/",
                  icon: <User />,
                },
              ]
            : []),

          ...(!personalizationDisabled
            ? [
                {
                  title: t("sidebar.dataPersonalisation"),
                  to: "/personalization",
                  icon: <Dashboard />,
                },
              ]
            : []),
          ...(!securityDisabled
            ? [
                {
                  title: t("sidebar.security"),
                  to: "/security",
                  icon: <Security />,
                },
              ]
            : []),
          ...(featureEnabled["subscriptions"] === true &&
          onlyForRoles([betaTester])
            ? [
                {
                  title: t("sidebar.subscriptions"),
                  to: SUBSCRIPTIONS_PATH,
                  icon: <Calendar />,
                },
              ]
            : []),
          ...(featureEnabled["payments"] === true && onlyForRoles([betaTester])
            ? [
                {
                  title: t("sidebar.payments"),
                  to: PAYMENTS_PATH,
                  icon: <Icon name="Payment" size={20} />,
                },
              ]
            : []),

          ...(featureEnabled["gifts"] === true && onlyForRoles([betaTester])
            ? [
                {
                  title: "Gifts & Vouchers",
                  to: GIFTS_PATH,
                  icon: <Voucher />,
                },
              ]
            : []),
          ...customRoutes,
        ]}
      />
    </>
  );
};
