import { FC, useEffect, useState } from "react";
import { LiveError, LivePreview, LiveProvider } from "react-live";

import moment from "moment";
import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { NavLink, useLocation } from "react-router-dom";
import { RecoveryEmailModal } from "../../components/security";
import {
  useFetch,
  usePreviewMode,
  useRenderPath,
  useToggle,
} from "../../hooks";
import { getUserInfo, SECURITY_PATH, templatesApiUrl } from "../../utils";
import { validateEmail } from "../../utils/validate-email";

export interface IRecoveryEmailResponse {
  email: string;
  otpCode?: string;
  otpToken?: string;
}

export interface IRecoveryEmail {
  email: string;
  updatedRecoveryDate: string;
  requestedDeleteRecoveryActive: boolean;
}

export const RecoveryEmail: FC = () => {
  const { t } = useTranslation();
  const { visible, toggle } = useToggle();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);
  const { pathname } = useLocation();
  const {
    profile: { email },
  } = getUserInfo();
  const [recoveryEmail, setRecoveryEmail] = useState<IRecoveryEmail>({
    email: "",
    updatedRecoveryDate: "",
    requestedDeleteRecoveryActive: false,
  });

  const [recoveryEmailData, setRecoveryEmailData] =
    useState<IRecoveryEmailResponse>({
      email: "",
      otpCode: "",
      otpToken: "",
    });

  const alias = pathname.replace("/", "");

  const {
    apiCall: getRecoveryEmailTemplate,
    response: recoveryEmailTemplate,
    loading: recoveryEmailTemplateLoading,
  } = useFetch("get");

  const { apiCall: recoveryEmailSetup, loading } = useFetch("post");
  const { apiCall: recoveryEmailReset, loading: recoveryEmailResetLoading } =
    useFetch("post");
  const {
    apiCall: getRecoveryEmail,
    response,
    loading: getRecoveryEmailloading,
  } = useFetch("get");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getRecoveryEmail(`ManageApi/GetRecoveryEmail`);
    getRecoveryEmailTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRecoveryEmail(response);
  }, [response]);

  const onRecoveryEmailSetup: SubmitHandler<any> = (data: {
    email: string;
  }) => {
    recoveryEmailSetup(
      `ManageApi/AddRecoveryEmail`,
      data,
      (data) => {
        toggle();
        setRecoveryEmailData(data);
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  const onResetRecoveryEmail = () => {
    recoveryEmailReset(
      `ManageApi/RemoveRecoveryEmail`,
      {},
      (res) => {
        toast.success(res);
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  const scope = {
    onResetRecoveryEmail,
    onRecoveryEmailSetup,
    register,
    handleSubmit,
    errors,
    getRecoveryEmailloading,
    renderPath,
    NavLink,
    Icon,
    SECURITY_PATH,
    t,
    recoveryEmail,
    loading,
    validateEmail,
    email,
    moment,
    recoveryEmailResetLoading,
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("recoverEmail.title")}</title>
        <style>{recoveryEmailTemplate?.css}</style>
      </Helmet>

      {!recoveryEmailTemplateLoading && (
        <LiveProvider
          code={recoveryEmailTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}

      {visible && (
        <RecoveryEmailModal
          data={recoveryEmailData}
          setRecoveryEmailData={setRecoveryEmailData}
          setRecoveryEmail={setRecoveryEmail}
          visible={visible}
          hide={toggle}
        />
      )}
    </>
  );
};
