import { FC, MouseEvent } from "react";
import { cx } from "../../../utils";

interface ITacSidebarItem {
  label: string;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const TermsSidebarItem: FC<ITacSidebarItem> = ({ label, onClick }) => {
  return (
    <li>
      <button
        type="button"
        onClick={onClick}
        className={cx([
          "py-3 px-5 w-full list-item rounded-md",
          "text-sm text-left",
          "hover:text-primary",
          "focus:outline-none",
        ])}
      >
        {label}
      </button>
    </li>
  );
};

export default TermsSidebarItem;
