import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { useFormContext, useWatch } from "react-hook-form";
import { LiveError, LivePreview, LiveProvider } from "react-live-runner";
import { Animation } from "..";
import { useUserContext } from "../../context";
import { useFetch, usePreviewMode } from "../../hooks";
import { cx, templatesApiUrl } from "../../utils";
import { Eye, EyeClosed } from "../icons";
import { PasswordField } from "./PasswordField";

interface IPasswordField {
  passwordSettings: {
    requiredLength: number;
    requireDigit: string;
    requireLowercase: string;
    requireNonAlphanumeric: string;
    requireUppercase: string;
    canNotContainEmail: string;
    canNotContainFirstName: string;
    canNotContainLastName: string;
  };
}

export function NewPasswordField({ passwordSettings }: IPasswordField) {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { isPreviewMode } = usePreviewMode();

  const {
    apiCall: getNewPasswordTemplate,
    response: newPasswordTemplate,
    loading: newPasswordTemplateLoading,
  } = useFetch("get");

  const {
    register,
    formState: { errors },
  } = useFormContext();
  const [newPass, setNewPass] = useState<boolean>(true);
  const {
    requiredLength,
    requireDigit,
    requireLowercase,
    requireNonAlphanumeric,
    requireUppercase,
    canNotContainEmail,
    canNotContainFirstName,
    canNotContainLastName,
  } = !!passwordSettings && passwordSettings;

  const newPwdValue = useWatch({
    name: "newPassword",
  });

  useEffect(() => {
    getNewPasswordTemplate(templatesApiUrl("newPasswordField", isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const itContainsString = useCallback((valueFrom: string, valueAt: string) => {
    return !(valueFrom?.toLowerCase().indexOf(valueAt?.toLowerCase()) >= 0);
  }, []);

  const hasNonAlphaNumeric = useCallback((value: string) => {
    return /([^a-zA-Z\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z\d])+/.test(
      value
    );
  }, []);

  const hasMinOneDigit = useCallback((value: string) => {
    return /\d/.test(value);
  }, []);

  const hasMinOneUpperCase = useCallback((value: string) => {
    return /^(?=.*[A-Z])/.test(value);
  }, []);

  const hasMinOneLowerCase = useCallback((value: string) => {
    return /^(?=.*[a-z])/.test(value);
  }, []);

  const hasMinLength = Boolean(requiredLength)
    ? newPwdValue?.length >= requiredLength
    : true;
  const hasOneDigit = requireDigit ? hasMinOneDigit(newPwdValue) : true;
  const hasUpperCase = requireUppercase
    ? hasMinOneUpperCase(newPwdValue)
    : true;
  const hasLowerCase = requireLowercase
    ? hasMinOneLowerCase(newPwdValue)
    : true;
  const hasAlphaNumeric = requireNonAlphanumeric
    ? hasNonAlphaNumeric(newPwdValue)
    : true;
  const containFirstName = canNotContainFirstName
    ? itContainsString(newPwdValue, user?.firstName)
    : true;
  const containLastName = canNotContainLastName
    ? itContainsString(newPwdValue, user?.lastName)
    : true;
  const containEmail = canNotContainEmail
    ? itContainsString(newPwdValue, user?.email)
    : true;

  const allCriteriaAreValid =
    hasMinLength &&
    hasOneDigit &&
    hasUpperCase &&
    hasLowerCase &&
    hasAlphaNumeric &&
    containFirstName &&
    containLastName &&
    containEmail;

  const scope = {
    t,
    errors,
    register,
    newPass,
    setNewPass,
    allCriteriaAreValid,
    requiredLength,
    cx,
    requireNonAlphanumeric,
    hasNonAlphaNumeric,
    requireUppercase,
    hasMinOneUpperCase,
    requireLowercase,
    hasMinOneLowerCase,
    canNotContainFirstName,
    user,
    itContainsString,
    canNotContainLastName,
    canNotContainEmail,
    requireDigit,
    hasMinOneDigit,
    hasAlphaNumeric,
    hasOneDigit,
    hasUpperCase,
    hasLowerCase,
    containFirstName,
    containLastName,
    containEmail,
    newPwdValue,
    Animation,
    Icon,
    Eye,
    EyeClosed,
    PasswordField,
  };

  return (
    <>
      {newPasswordTemplateLoading ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <LiveProvider
          code={
            isPreviewMode
              ? newPasswordTemplate?.previewHtml || "<></>"
              : newPasswordTemplate?.html || "<></>"
          }
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
}
