import { useEffect } from "react";
import { useFetch } from ".";

export const useHasPasswordApi = (makeACall?: boolean) => {
  const {
    apiCall: getHasPassword,
    response: hasPassword,
    loading,
  } = useFetch("get");

  useEffect(() => {
    getHasPassword("Users/HasPassword", {}, () => {});
  }, [makeACall]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    hasPassword,
    loading,
  };
};
