import { FC, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink, useLocation } from "react-router-dom";
import {
  useFetch,
  useHasPasswordApi,
  usePreviewMode,
  useRenderPath,
  useToggle,
} from "../../hooks";
import { IDeletePersonalData } from "../../interfaces";
import { eventDeletePersonalData } from "../../utils/analytics";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { Eye, EyeClosed } from "../../components/icons";
import { templatesApiUrl } from "../../utils";

export const DeleteAccount: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [passType, setPassType] = useState<boolean>(true);
  const { visible, toggle } = useToggle();

  const { apiCall: deleteAccount, loading } = useFetch("post");
  const { hasPassword } = useHasPasswordApi(true);

  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const alias = pathname.replace("/", "");

  const {
    apiCall: getDeleteDataTemplate,
    response: deleteDataTemplate,
    loading: deleteDataTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getDeleteDataTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const hideModal = () => {
    toggle();
    reset();
  };

  const onDeleteAccount: SubmitHandler<any> = (data: IDeletePersonalData) => {
    const deleteObj = {
      requirePassword: hasPassword || false,
      password: data?.password || "string",
    };

    deleteAccount(
      "ManageApi/DeletePersonalData",
      deleteObj,
      (response) => {
        toast.success(response);
        eventDeletePersonalData();
        hideModal();
      },
      (error) => {
        const err = error.response?.data;
        toast.error(t<string>(err));
      }
    );
  };

  const scope = {
    Icon,
    NavLink,
    renderPath,
    t,
    toggle,
    visible,
    hasPassword,
    passType,
    setPassType,
    errors,
    handleSubmit,
    onDeleteAccount,
    register,
    hideModal,
    loading,
    Eye,
    EyeClosed,
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("deleteData.deleteDataTitle")}</title>
        <style>{deleteDataTemplate?.css}</style>
      </Helmet>

      {!deleteDataTemplateLoading && (
        <LiveProvider code={deleteDataTemplate?.html || "<></>"} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
