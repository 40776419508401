import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { PageHeader, PageInfoItem } from "../../components";
import { MainContainer } from "../../containers";
import { PAYMENTS_LIST_PATH } from "../../utils";

export const Payments: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t<string>("payments.title")}</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          title={t("payments.title")}
          description={t("payments.description")}
          iconUrl="images/payments-icon.svg"
        />

        <div className="mt-14">
          <PageInfoItem
            title={t("payments.paymentInfo")}
            description={t("payments.paymentInfoDescription")}
            iconName="subscription-icon.png"
            to={`${PAYMENTS_LIST_PATH}?type=info`}
          />

          <PageInfoItem
            title={t("payments.paymentHistory")}
            description={t("payments.paymentHistoryDescription")}
            iconName="payment-history.png"
            to={`${PAYMENTS_LIST_PATH}?type=history`}
            lastChild
          />
        </div>
      </MainContainer>
    </>
  );
};
