import { FC, useEffect } from "react";

import { TermsContainer } from "../../components/terms/TermsContainer";
import { useFetch } from "../../hooks";

export const Terms: FC = () => {
  const { apiCall: getTermsConditions, response: terms } = useFetch("get");

  useEffect(() => {
    getTermsConditions("TermsConditionsApi/GetAll");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TermsContainer>
      <p>{!!terms && terms[0]?.termsText}</p>
    </TermsContainer>
  );
};

export default Terms;
