import { FC } from "react";

export const Dashboard: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 2C3.34315 2 2 3.34315 2 5V8C2 9.65685 3.34315 11 5 11H8C9.65685 11 11 9.65685 11 8V5C11 3.34315 9.65685 2 8 2H5ZM8 3.5H5C4.17157 3.5 3.5 4.17157 3.5 5V8C3.5 8.82843 4.17157 9.5 5 9.5H8C8.82843 9.5 9.5 8.82843 9.5 8V5C9.5 4.17157 8.82843 3.5 8 3.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2C14.3431 2 13 3.34315 13 5V19C13 20.6569 14.3431 22 16 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2H16ZM19 3.5H16C15.1716 3.5 14.5 4.17157 14.5 5V19C14.5 19.8284 15.1716 20.5 16 20.5H19C19.8284 20.5 20.5 19.8284 20.5 19V5C20.5 4.17157 19.8284 3.5 19 3.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 16C2 14.3431 3.34315 13 5 13H8C9.65685 13 11 14.3431 11 16V19C11 20.6569 9.65685 22 8 22H5C3.34315 22 2 20.6569 2 19V16ZM5 14.5H8C8.82843 14.5 9.5 15.1716 9.5 16V19C9.5 19.8284 8.82843 20.5 8 20.5H5C4.17157 20.5 3.5 19.8284 3.5 19V16C3.5 15.1716 4.17157 14.5 5 14.5Z"
      />
    </svg>
  );
};
