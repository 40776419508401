import { FC } from "react";

export const User: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6379 8C16.6379 10.7614 14.3994 13 11.6379 13C8.87652 13 6.63794 10.7614 6.63794 8C6.63794 5.23858 8.87652 3 11.6379 3C14.3994 3 16.6379 5.23858 16.6379 8ZM15.1379 8C15.1379 9.933 13.5709 11.5 11.6379 11.5C9.70494 11.5 8.13794 9.933 8.13794 8C8.13794 6.067 9.70494 4.5 11.6379 4.5C13.5709 4.5 15.1379 6.067 15.1379 8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6379 14.25C7.70912 14.25 5.17538 15.6891 3.6669 17.3865C3.25932 17.8451 3.03326 18.3703 3.00339 18.915C2.97376 19.4555 3.14018 19.9613 3.42545 20.3825C3.98908 21.2148 5.02953 21.75 6.17932 21.75H17.0966C18.2464 21.75 19.2868 21.2148 19.8504 20.3825C20.1357 19.9613 20.3021 19.4555 20.2725 18.915C20.2426 18.3703 20.0166 17.8451 19.609 17.3865C18.1005 15.6891 15.5668 14.25 11.6379 14.25ZM4.78812 18.3829C5.99571 17.0241 8.11258 15.75 11.6379 15.75C15.1633 15.75 17.2802 17.0241 18.4878 18.3829C18.6959 18.6171 18.7655 18.8278 18.7747 18.9971C18.7843 19.1706 18.7333 19.357 18.6085 19.5414C18.3518 19.9204 17.8006 20.25 17.0966 20.25H6.17932C5.4753 20.25 4.92411 19.9204 4.66743 19.5414C4.54254 19.357 4.49163 19.1706 4.50114 18.9971C4.51043 18.8278 4.57997 18.6171 4.78812 18.3829Z"
      />
    </svg>
  );
};
