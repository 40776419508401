import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink } from "react-router-dom";
import { useFetch, usePreviewMode, useRenderPath } from "../../hooks";
import {
  templatesApiUrl,
  USER_PROFILE_API,
  USER_PROFILE_API_HEADERS,
} from "../../utils";
import { cx } from "../../utils/classnames";
import { Dashboard, Security, User } from "../icons";
import { SidebarMenuProps } from "./SidebarMenu";

export interface SidebarProps extends SidebarMenuProps {
  wrapperClassName?: string;
}

export const Sidebar: FC<SidebarProps> = ({
  wrapperClassName,
  ulProps,
  list,
  ...props
}) => {
  const { t } = useTranslation();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const { apiCall: getModels, response } = useFetch("get");
  const {
    apiCall: getNavbarTemplate,
    response: navbarTemplate,
    loading: navbarTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getModels(
      `${USER_PROFILE_API}/api/integration-models/list/published`,
      USER_PROFILE_API_HEADERS
    );
    getNavbarTemplate(templatesApiUrl("navbar", isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const models = response?.data;

  const scope = {
    NavLink,
    User,
    Dashboard,
    Security,
    models,
    cx,
    renderPath,
    t,
  };

  return (
    <>
      <Helmet>
        <style>{navbarTemplate?.css}</style>
      </Helmet>
      {!navbarTemplateLoading && (
        <LiveProvider code={navbarTemplate?.html || "<></>"} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
