import { FC } from "react";

export const Security: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path d="M16.5483 9.51174C16.8309 9.20893 16.8146 8.73434 16.5117 8.45171C16.2089 8.16909 15.7343 8.18545 15.4517 8.48826L10.4 13.9008L8.54829 11.9168C8.26567 11.614 7.79108 11.5977 7.48826 11.8803C7.18545 12.1629 7.16909 12.6375 7.45171 12.9403L9.48618 15.1201C9.98035 15.6496 10.8196 15.6496 11.3138 15.1201L16.5483 9.51174Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3167 2.69287C12.4678 2.37451 11.5322 2.37451 10.6833 2.69287L5.68329 4.56787C4.21965 5.11673 3.25 6.51593 3.25 8.0791V10.8198C3.25 14.8916 5.55052 18.6139 9.19245 20.4349L10.3229 21.0001C11.3787 21.528 12.6213 21.528 13.6771 21.0001L14.8075 20.4349C18.4495 18.6139 20.75 14.8916 20.75 10.8198V8.0791C20.75 6.51593 19.7804 5.11673 18.3167 4.56787L13.3167 2.69287ZM11.21 4.09736C11.7193 3.90635 12.2807 3.90635 12.79 4.09736L17.79 5.97236C18.6682 6.30168 19.25 7.1412 19.25 8.0791V10.8198C19.25 14.3234 17.2705 17.5263 14.1367 19.0932L13.0062 19.6585C12.3728 19.9752 11.6272 19.9752 10.9938 19.6585L9.86327 19.0932C6.72952 17.5263 4.75 14.3234 4.75 10.8198V8.0791C4.75 7.1412 5.33179 6.30168 6.20997 5.97236L11.21 4.09736Z"
      />
    </svg>
  );
};
