import { useAuth } from "oidc-react";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { LiveError, LivePreview, LiveProvider } from "react-live";
import { NavLink } from "react-router-dom";
import { MainContainer } from "../../containers";
import { useUserContext } from "../../context";
import { useFetch, usePreviewMode, useRenderPath } from "../../hooks";
import { cx, templatesApiUrl } from "../../utils";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { SectionLoader } from "../../components/account";

export const MyAccount: FC = () => {
  const { t } = useTranslation();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const auth = useAuth();
  const { user, loading: userLoading, picture } = useUserContext();
  const userId = auth?.userData?.profile?.sub || "";
  const {
    apiCall: getExternalProviders,
    response: providers,
    loading,
  } = useFetch("get");

  const {
    apiCall: getMyAccountTemplate,
    response: accountTemplate,
    loading: accountTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getExternalProviders(`Auth/ExternalLogins?userId=${userId}`);
    getMyAccountTemplate(templatesApiUrl("myaccount", isPreviewMode));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const currentLogins = providers?.currentLogins;

  const userLoginProviders = currentLogins?.map(
    (provider: {
      loginProvider: string;
      providerDisplayName: string;
      providerKey: string;
    }) => {
      return " " + provider?.loginProvider;
    }
  );

  const scope = {
    NavLink,
    user,
    picture,
    userLoading,
    t,
    cx,
    Icon,
    currentLogins,
    userLoginProviders,
    loading,
    SectionLoader,
    renderPath,
  };

  return (
    <>
      <Helmet>
        <title className="text-primary">
          {t<string>("homePage.myAccount")}
        </title>
        <style>{accountTemplate?.css}</style>
      </Helmet>
      <MainContainer>
        {!accountTemplateLoading && (
          <LiveProvider code={accountTemplate?.html || "<>/</>"} scope={scope}>
            <LivePreview />
            <LiveError />
          </LiveProvider>
        )}
      </MainContainer>
    </>
  );
};
