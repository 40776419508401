import { FC } from "react";

export const Calendar: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path d="M8 14C8 14.5523 7.55228 15 7 15C6.44772 15 6 14.5523 6 14C6 13.4477 6.44772 13 7 13C7.55228 13 8 13.4477 8 14Z" />
      <path d="M12 15C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14C11 14.5523 11.4477 15 12 15Z" />
      <path d="M18 14C18 14.5523 17.5523 15 17 15C16.4477 15 16 14.5523 16 14C16 13.4477 16.4477 13 17 13C17.5523 13 18 13.4477 18 14Z" />
      <path d="M7 19C7.55228 19 8 18.5523 8 18C8 17.4477 7.55228 17 7 17C6.44772 17 6 17.4477 6 18C6 18.5523 6.44772 19 7 19Z" />
      <path d="M13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17C12.5523 17 13 17.4477 13 18Z" />
      <path d="M17 19C17.5523 19 18 18.5523 18 18C18 17.4477 17.5523 17 17 17C16.4477 17 16 17.4477 16 18C16 18.5523 16.4477 19 17 19Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.25C7.41421 2.25 7.75 2.58579 7.75 3V4H16.25V3C16.25 2.58579 16.5858 2.25 17 2.25C17.4142 2.25 17.75 2.58579 17.75 3V4H19C20.6569 4 22 5.34315 22 7V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V7C2 5.34315 3.34315 4 5 4H6.25V3C6.25 2.58579 6.58579 2.25 7 2.25ZM16.25 5.5V7C16.25 7.41421 16.5858 7.75 17 7.75C17.4142 7.75 17.75 7.41421 17.75 7V5.5H19C19.8284 5.5 20.5 6.17157 20.5 7V9.25H3.5V7C3.5 6.17157 4.17157 5.5 5 5.5H6.25V7C6.25 7.41421 6.58579 7.75 7 7.75C7.41421 7.75 7.75 7.41421 7.75 7V5.5H16.25ZM3.5 10.75V19C3.5 19.8284 4.17157 20.5 5 20.5H19C19.8284 20.5 20.5 19.8284 20.5 19V10.75H3.5Z"
      />
    </svg>
  );
};
