import axios from "axios";
import { useCallback, useState } from "react";

type RequestMethodTypes = "get" | "post" | "delete" | "put"

export interface IRequestState {
    loading: boolean;
    errors: string | null
    response: any;
}

const useFetch = (method: RequestMethodTypes, loading: boolean = true) => {
  const [requestState, setRequestState] = useState<IRequestState>({
      loading: method === "get" && loading ? true : false,
      errors: null,
      response: null
  })

  const apiCall = useCallback(async(url: string,
    data?: object | string,
    successCallback?: (response: any) => void,
    errorCallback?: (error: any) => void)  => {
     try {
        setRequestState((current) => 
        !current.loading ? {...current, loading: true} : current);

        const responseReq = await axios[method](url, data);
        const response = await responseReq?.data;

        if(Boolean(response) || (responseReq?.status === 200 || responseReq?.status === 201)) {
            setRequestState(() => ({
                errors: null,
                response,
                loading: false,
            }))

            successCallback && successCallback(response);
        } else {
            setRequestState((current) => ({
                ...current,
                errors: response?.message || "Api Error",
                loading: false
            }))

            errorCallback && errorCallback(response)
        }   
     } catch(error: any) {
       setRequestState((current) => ({
           ...current,
           errors: error?.message || "Api Error",
           loading: false
       }))
       errorCallback && errorCallback(error)
           
     }
 // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

return {
    apiCall, ...requestState
  }
}

export { useFetch };

