import { FC, ReactNode } from "react";
import { cx } from "../../utils";

interface IModalFooter {
  className?: string;
  children: ReactNode;
}

export const ModalFooter: FC<IModalFooter> = (props) => {
  return (
    <div
      className={cx(["py-6 sm:px-8 px-2 flex justify-end", props.className])}
    >
      {props.children}
    </div>
  );
};
