import { FC, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { routes } from "./Routes";

import i18next from "i18next";
import { onlyForRoles } from "../context";
import { Page404 } from "../pages";
import "../utils/i18";

export const Router: FC = () => {
  const [loaded, setHasLoaded] = useState(false);

  useEffect(() => {
    i18next.on("loaded", function (loaded) {
      if (loaded && Object.keys(loaded).length !== 0) {
        setHasLoaded(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loaded ? (
        <Routes>
          {routes.map((route: any, i) => {
            if (!!route?.permissions && onlyForRoles(route?.permissions)) {
              return (
                <Route
                  path={route.path}
                  element={route.element}
                  key={i}
                  index={route?.index}
                />
              );
            }

            return (
              <Route
                path={route.path}
                element={route.element}
                key={i}
                index={route?.index}
              />
            );
          })}

          <Route path="*" element={<Page404 />} />
        </Routes>
      ) : (
        <div className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-white">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};
