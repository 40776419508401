import { FC } from "react";

export const Check: FC = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="32" cy="32" r="32" fill="#36B37E" />
      <circle cx="32" cy="32.0016" r="22.4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0001 9.06641C19.3344 9.06641 9.06677 19.334 9.06677 31.9997C9.06677 44.6655 19.3344 54.9331 32.0001 54.9331C44.6658 54.9331 54.9334 44.6655 54.9334 31.9997C54.9334 19.334 44.6658 9.06641 32.0001 9.06641ZM42.2743 27.0359C42.8991 26.4111 42.8991 25.398 42.2743 24.7732C41.6495 24.1483 40.6364 24.1483 40.0115 24.7732L28.9524 35.8323L23.9886 30.8684C23.3637 30.2436 22.3507 30.2436 21.7258 30.8684C21.101 31.4932 21.101 32.5063 21.7258 33.1311L27.6325 39.0378C28.3615 39.7668 29.5434 39.7668 30.2724 39.0378L42.2743 27.0359Z"
        fill="#36B37E"
      />
    </svg>
  );
};
