import { FC } from "react";
import { cx } from "../../utils";

interface IParagraph {
  text: string;
  noMargin?: boolean;
  mb?: string;
  className?: string;
}

export const Paragraph: FC<IParagraph> = ({
  text,
  noMargin,
  mb,
  className,
}) => {
  return (
    <p
      className={cx([
        "text-sm text-neutral",
        mb ? mb : noMargin ? "mb-0" : "mb-5",
        className && className,
      ])}
    >
      {text}
    </p>
  );
};
