import { FC } from "react";

import { useNavigate } from "react-router-dom";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Divider } from "../ui/Divider";
import { ISubPageHeader } from "../../interfaces";
import { NavLink } from "react-router-dom";
import { cx } from "../../utils";

export const SubPageHeader: FC<ISubPageHeader> = ({
  title,
  description,
  headlineContent,
  noDescription,
  to,
}) => {
  const navigate = useNavigate();

  const backBtnStyle = cx([
    "w-10 h-10 rounded transition bg-transparent text-gray-700 flex items-center justify-center mr-2 sm:mr-0",
    "focus:outline-none hover:text-primary",
    "mb-2 md:ml-0 sm:mb-0 p-0",
  ]);

  const backTo = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="flex mt-4 sm:mt-0">
        {Boolean(to) ? (
          <NavLink
            to={to || "/"}
            className={cx([
              backBtnStyle,
              "focus:shadow-outlineGray focus:text-primary-mainText",
            ])}
          >
            <div>
              <Icon name="ArrowLeft" size={24} className="mx-auto" />
            </div>
          </NavLink>
        ) : (
          <button
            onClick={backTo}
            type="button"
            className={cx([
              backBtnStyle,
              "focus:shadow-outlineGray focus:text-primary-mainText",
            ])}
            title="Back"
          >
            <div>
              <Icon name="ArrowLeft" size={24} className="mx-auto" />
            </div>
          </button>
        )}

        <div className="sm:ml-2 md:ml-6 flex-1">
          <h4 className="text-black font-medium">{title}</h4>
          {!noDescription && (
            <p className="mt-1 text-neutral text-sm">{description}</p>
          )}

          {headlineContent}
        </div>
      </div>

      <Divider className="mt-6 sm:my-8" />
    </>
  );
};
