import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { PageHeader, PageInfoItem } from "../../components";
import { MainContainer } from "../../containers";
import { GIFTS_PATH_LIST } from "../../utils";

export const Gifts: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t<string>("gifts.title")}</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          title={t("gifts.title")}
          description={t("gifts.description")}
          iconUrl="images/gift-icon.svg"
        />

        <div className="mt-14">
          <PageInfoItem
            title={t("gifts.gifts")}
            description={t("gifts.manageGifts")}
            iconName="gift-icon.png"
            to={`${GIFTS_PATH_LIST}?type=gifts`}
          />

          <PageInfoItem
            title={t("gifts.vouchers")}
            description={t("gifts.manageVouchers")}
            iconName="voucher-icon.png"
            to={`${GIFTS_PATH_LIST}?type=vouchers`}
          />

          <PageInfoItem
            title={t("gifts.externalVouchers")}
            description={t("gifts.manageExternalVouchers")}
            iconName="voucher-icon.png"
            to={`${GIFTS_PATH_LIST}?type=external-vouchers`}
            lastChild
          />
        </div>
      </MainContainer>
    </>
  );
};
