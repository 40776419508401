import { FC, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Helmet } from "react-helmet";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { useFetch, usePreviewMode } from "../../hooks";
import { IRecoveryEmailResponse } from "../../pages/security/RecoveryEmail";
import { templatesApiUrl } from "../../utils";

interface IRecoveryEmailModal {
  visible: boolean;
  hide: () => void;
  data: IRecoveryEmailResponse;
  setRecoveryEmailData: any;
  setRecoveryEmail: any;
}

export const RecoveryEmailModal: FC<IRecoveryEmailModal> = ({
  visible,
  hide,
  data,
  setRecoveryEmail,
  setRecoveryEmailData,
}) => {
  const { t } = useTranslation();
  const { isPreviewMode } = usePreviewMode();
  const { apiCall: confirmRecoveryEmail, loading } = useFetch("post");
  const { apiCall: recoveryEmailResend, loading: recoveryEmailResendLoading } =
    useFetch("post");

  const {
    apiCall: getRecoveryEmailModalTemplate,
    response: recoveryEmailModalTemplate,
    loading: recoveryEmailModalTemplateLoading,
  } = useFetch("get");

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getRecoveryEmailModalTemplate(
      templatesApiUrl("recovery-email-modal", isPreviewMode)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmRecoveryEmail = (allData: any) => {
    const dataObj = {
      ...data,
      otpCode: allData.code,
    };
    confirmRecoveryEmail(
      `ManageApi/VerifyRecoveryEmail`,
      dataObj,
      (data) => {
        toast.success(t<string>("recoverEmail.recoveryEmailSetupSuccessfully"));
        hide();
        reset();
        setRecoveryEmail(data);
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  const onRecoveryEmailResend = () => {
    const recoveryEmailObj = {
      email: data.email,
    };
    recoveryEmailResend(
      `ManageApi/AddRecoveryEmail`,
      recoveryEmailObj,
      (data) => {
        setRecoveryEmailData(data);
        toast.success(t<string>("recoverEmail.recoveryCodeResent"));
      },
      (error) => {
        toast.error(error?.response?.data);
      }
    );
  };

  const scope = {
    onRecoveryEmailResend,
    t,
    onConfirmRecoveryEmail,
    handleSubmit,
    register,
    visible,
    recoveryEmailResendLoading,
    loading,
    hide,
    Icon,
    data,
    errors,
  };

  return (
    <>
      <Helmet>
        <style>{recoveryEmailModalTemplate?.css}</style>
      </Helmet>

      {!recoveryEmailModalTemplateLoading && (
        <LiveProvider
          code={recoveryEmailModalTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
