import { ReactElement, ReactNode, useEffect } from "react";

import { Animation } from "../";
import useOnclickOutside from "../../hooks/useOnclickOutside";
import { cx } from "../../utils";

interface IDropdown {
  className?: string;
  innerElementClassName?: string;
  children: ReactElement;
  dropdownContent: ReactNode;
  left?: boolean;
  noPadding?: boolean;
  width?: string;
  dropdownClassName?: string;
  dropdownVisibility?: (opened: boolean) => void;
  style?: Object;
  dropdownOpened?: boolean;
}

export const Dropdown: React.FC<IDropdown> = ({
  className,
  children,
  dropdownContent,
  left,
  width,
  noPadding,
  innerElementClassName,
  dropdownVisibility,
  dropdownClassName,
  style,
  dropdownOpened,
}) => {
  const { ref, isVisible, setIsVisible } = useOnclickOutside(false);

  const toggle = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    dropdownVisibility && dropdownVisibility(isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    dropdownOpened && setIsVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOpened]);

  return (
    <div className={cx(["relative", className])} ref={ref}>
      <div className={innerElementClassName} onClick={toggle}>
        {children}
      </div>

      <Animation show={isVisible} type="fade-bottom">
        <div
          className={cx([
            "dropdown__container animate-fade-in",
            left && "left-0",
            !left && "right-0",
            !width && "w-56",
            width,
            !noPadding && "p-2",
            dropdownClassName,
          ])}
          style={style}
        >
          {dropdownContent}
        </div>
      </Animation>
    </div>
  );
};
