import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import ReactPagination from "react-paginate";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Dropdown, Loader } from "../../components";
import {
  useCurrentPage,
  useFetch,
  usePreviewMode,
  useRenderPath,
  useToggle,
} from "../../hooks";
import { cx, getUserInfo, templatesApiUrl } from "../../utils";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";
import { Helmet } from "react-helmet";
import { ThreeDotsIcon } from "../../components/icons/ThreeDotsIcon";

interface IGrant {
  apiGrantNames: string[];
  clientId: string;
  clientLogoUrl: string;
  clientName: string;
  clientUrl: string;
  created: string;
  description: string;
  expires: string;
  identityGrantNames: string[];
}

export const Permissions = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    profile: { sub },
  } = getUserInfo();

  const { toggle, visible } = useToggle();
  const { currentPage, handlePageChange } = useCurrentPage();
  const { isPreviewMode } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const PER_PAGE = 10;

  const [grants, setGrants] = useState<IGrant[]>();
  const [grant, setGrant] = useState<IGrant>();

  const alias = pathname.replace("/", "");

  const {
    apiCall: getPermissionsTemplate,
    response: permissionsTemplate,
    loading: permissionsTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    getPermissionsTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { apiCall: getUserGrants, response, loading } = useFetch("get");
  const { apiCall: revokeAccess, loading: revokeAccessLoading } =
    useFetch("post");

  useEffect(() => {
    getUserGrants(
      `PersistedGrants/GetUserGrants?userId=${sub}&page=${currentPage}&pageSize=${PER_PAGE}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGrants(response?.grants);
    // setGrants([]);
  }, [response]);

  const onRevokeAccess = () => {
    revokeAccess(
      `PersistedGrants/RevokeByUser?clientId=${grant?.clientId}&userId=${sub}`,
      {},
      () => {
        toast.success(t("permissions.accessRevokedSuccessfully"));
        toggle();
        const filteredGrants = grants?.filter(
          (grantItem) => grantItem.clientId !== grant?.clientId
        );
        setGrants(filteredGrants);
      }
    );
  };

  const scope = {
    t,
    cx,
    loading,
    grants,
    format,
    toggle,
    setGrant,
    NavLink,
    renderPath,
    Icon,
    Dropdown,
    ThreeDotsIcon,
    visible,
    onRevokeAccess,
    revokeAccessLoading,
    // pagination
    ReactPagination,
    handlePageChange,
    currentPage,
    pageSize: 10,
    totalCount: grants?.length,
    onPageChange: handlePageChange,
    page: currentPage,
    length: grants?.length,
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{t<string>("security.permissions")}</title>
        <style>{permissionsTemplate?.css}</style>
      </Helmet>

      {!permissionsTemplateLoading && (
        <LiveProvider code={permissionsTemplate?.html || "<></>"} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
