import { FC, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { Helmet } from "react-helmet";
import { useTfaContext } from "../../context/TfaContext";
import { useFetch, usePreviewMode } from "../../hooks";
import { IOtpEmail } from "../../interfaces";
import { templatesApiUrl, TFA_RECOVERY_CODES_PATH } from "../../utils";
import { eventEnableTfa, TfaTypes } from "../../utils/analytics";

export interface OtpEmailObject {
  email: string;
  otpCode: string;
  otpToken: string;
}

interface IEmailAuthModal {
  otpEmailObj?: OtpEmailObject;
  visible: boolean;
  hide: () => void;
}

export const EmailAuthModal: FC<IEmailAuthModal> = ({
  otpEmailObj,
  visible,
  hide,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setTfaData, tfa } = useTfaContext();
  const { apiCall: enableEmailTfa, loading } = useFetch("post");
  const { isPreviewMode } = usePreviewMode();

  const {
    apiCall: getEmailAuthModal,
    response: emailAuthModal,
    loading: emailAuthModalLoading,
  } = useFetch("get");

  useEffect(() => {
    getEmailAuthModal(templatesApiUrl("email-auth-modal", isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const enableEmail2fa = (data: IOtpEmail) => {
    const email2faObject = {
      ...data,
      email: otpEmailObj?.email || "",
      otpToken: otpEmailObj?.otpToken || "",
    };

    enableEmailTfa(
      `ManageApi/EnableEmail2fa`,
      email2faObject,
      (response) => {
        toast.success(
          `${t(
            "2fa/emailAuthenticator.emailAuthenticatorEnabledSuccessfully"
          )}!`
        );

        const { userContact, date } = response;

        if (!!tfa?.recoveryCodesLeft && tfa?.recoveryCodesLeft > 0) {
          setTfaData({
            ...tfa,
            emailOtpEnabled: true,
            emailUsed: userContact,
            emailOtpEnabledDate: date,
            recoveryCodesLeft: 10,
          });
        } else {
          navigate(TFA_RECOVERY_CODES_PATH, {
            state: {
              codes: response,
            },
          });

          setTfaData({
            ...tfa,
            emailOtpEnabled: true,
            emailOtpEnabledDate: new Date(),
            emailUsed: otpEmailObj?.email,
            recoveryCodesLeft: 10,
          });
        }

        eventEnableTfa(TfaTypes.email);
        reset();
        hide();
      },
      (error) => {
        if (error.response?.status === 400) {
          // TODO: should get this text from response
          toast.error(t("2fa/emailAuthenticator.codeNotValid"));
        }
      }
    );
  };

  const scope = {
    enableEmail2fa,
    handleSubmit,
    register,
    Icon,
    visible,
    hide,
    t,
    otpEmailObj,
    errors,
    loading,
  };

  return (
    <>
      <Helmet>
        <style>{emailAuthModal?.css}</style>
      </Helmet>

      {!emailAuthModalLoading && (
        <LiveProvider code={emailAuthModal?.html || "<></>"} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
