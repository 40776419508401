import { createContext, useContext, useEffect } from "react";
import { useFetch } from "../hooks";

const OrganizationSettingsContext = createContext<any>(null);

const OrganizationSettingsProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const { apiCall: getOrganizationSettings, response: organization } =
    useFetch("get");

  useEffect(() => {
    getOrganizationSettings("OrganizationSettings/OrganizationSettings");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OrganizationSettingsContext.Provider
      value={{
        organization,
      }}
    >
      {children}
    </OrganizationSettingsContext.Provider>
  );
};

const useOrganizationSettingsContext = () => {
  const context = useContext(OrganizationSettingsContext);
  if (context === (undefined || null)) {
    throw new Error(
      "useOrganizationSettingsContext must be used within a TfaProvider"
    );
  }
  return context;
};

export {
  OrganizationSettingsContext,
  OrganizationSettingsProvider,
  useOrganizationSettingsContext,
};
