import { FC } from "react";

export const Voucher: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current"
    >
      <path d="M7.73173 9.89844C7.53647 9.70317 7.21989 9.70317 7.02463 9.89844C6.82937 10.0937 6.82936 10.4103 7.02463 10.6055L7.54745 11.1284C7.74271 11.3236 8.05929 11.3236 8.25455 11.1284C8.44982 10.9331 8.44982 10.6165 8.25455 10.4213L7.73173 9.89844Z" />
      <path d="M9.50932 11.676C9.31406 11.4808 8.99748 11.4808 8.80222 11.676C8.60695 11.8713 8.60695 12.1879 8.80221 12.3831L9.32503 12.906C9.5203 13.1012 9.83688 13.1012 10.0321 12.9059C10.2274 12.7107 10.2274 12.3941 10.0321 12.1988L9.50932 11.676Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.1688 3.07894C12.3226 2.2328 10.9508 2.2328 10.1047 3.07894L3.03359 10.15C2.18745 10.9961 2.18745 12.368 3.03359 13.2141L3.46222 13.6428C3.89447 14.075 4.56995 14.1466 5.08318 13.8145C5.39144 13.615 5.71307 13.6871 5.93353 13.9097C6.15587 14.1343 6.23232 14.4709 6.0346 14.7899C5.7177 15.3012 5.78936 15.9699 6.2186 16.3991L6.73748 16.918C7.58361 17.7642 8.95547 17.7642 9.80161 16.918L16.8727 9.84696C17.7188 9.00083 17.7188 7.62897 16.8727 6.78283L13.1688 3.07894ZM10.8118 3.78605C11.2674 3.33043 12.0061 3.33043 12.4617 3.78605L16.1656 7.48994C16.6212 7.94555 16.6212 8.68424 16.1656 9.13985L11.5687 13.7367C11.5622 13.7293 11.5554 13.7221 11.5483 13.715L11.2869 13.4536C11.0916 13.2583 10.7751 13.2583 10.5798 13.4536C10.3845 13.6489 10.3845 13.9655 10.5798 14.1607L10.8412 14.4221C10.8483 14.4292 10.8555 14.436 10.8629 14.4426L9.0945 16.2109C8.63889 16.6665 7.9002 16.6665 7.44459 16.2109L6.92571 15.692C6.82865 15.595 6.80934 15.4381 6.88457 15.3168C7.35852 14.5521 7.14614 13.7131 6.64414 13.2061C6.14026 12.6972 5.30205 12.4818 4.53993 12.9749C4.42259 13.0509 4.26815 13.0345 4.16933 12.9357L3.74069 12.507C3.28508 12.0514 3.28508 11.3127 3.74069 10.8571L5.50845 9.08936L5.76986 9.35077C5.96512 9.54604 6.2817 9.54604 6.47697 9.35077C6.67223 9.15551 6.67223 8.83893 6.47697 8.64367L6.21556 8.38226L10.8118 3.78605Z"
      />
    </svg>
  );
};
