import axios from "axios";
import { getUserInfo } from "./web-storage-controller";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/api`;
axios.defaults.headers = {
    "Content-Type": "application/json, text/plain, */*",
};

export const setupAxios = (history: any) => {
    // request intercpetor
    axios.interceptors.request.use((request) => {
        const {access_token} = getUserInfo()
        if (access_token) {
                request.headers.authorization = `Bearer ${access_token}`;
        }
        return request;
    });

    axios.interceptors.response.use(
        (response) => {
            return { ...response };
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

export { axios };
