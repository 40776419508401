
export const useRenderPath = (isPreviewMode: boolean) => {
    const renderPath = (name: string) => {
        return isPreviewMode ? `${name}?mode=preview` : name;
      };


  return {
    renderPath
  };
};
