import { FC, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { toast } from "react-toastify";
//@ts-ignore
import Icon from "@gjirafatech/gjirafa-icons/Icon";

import { NavLink, useLocation } from "react-router-dom";
import { TextMessageModal } from "../../components/security";
import { useTfaContext } from "../../context/TfaContext";
import {
  useFetch,
  usePreviewMode,
  useRenderPath,
  useToggle,
} from "../../hooks";
import { IOtpSms } from "../../interfaces";
import { templatesApiUrl, TFA_PATH } from "../../utils";
import { eventDisableTfa, TfaTypes } from "../../utils/analytics";

export const TextMessage: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { tfa, setTfaData } = useTfaContext();
  const { isPreviewMode, templateSearchParam } = usePreviewMode();
  const { renderPath } = useRenderPath(isPreviewMode);

  const { visible, toggle } = useToggle();
  const [optObj, setOptObj] = useState<IOtpSms>();
  const { smsOtpEnabled, smsOtpEnabledDate, phoneUsed } = tfa;

  const alias = pathname.split("/")[2];

  const {
    apiCall: getTextMessageAuthTemplate,
    response: textMessageAuthTemplate,
    loading: textMessageAuthTemplateLoading,
  } = useFetch("get");

  const { apiCall: sendOtpViaSMS, loading } = useFetch("post");
  const { apiCall: resetSmsOtp, loading: resetSmsOtpLoading } =
    useFetch("post");

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getTextMessageAuthTemplate(templatesApiUrl(alias, isPreviewMode));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSendOtpViaSMS: SubmitHandler<any> = (data: IOtpSms) => {
    const phoneNumber = data?.phoneNumber.replace(/\s+/g, "");
    const otpObject = {
      phoneNumber,
      otpCode: "string",
      otpToken: "string",
    };

    sendOtpViaSMS(
      "ManageApi/SendOtpViaSMS",
      otpObject,
      (response) => {
        toast.success(t<string>("2fa/textMessage.sixDigitCodeSent"));
        toggle();
        setOptObj(response);
      },
      (error) => {
        toast.error(
          !!error.response.data?.errors?.PhoneNumber &&
            error.response.data?.errors?.PhoneNumber[0]
        );
      }
    );
  };

  const onResetSmsOtp = () => {
    resetSmsOtp("ManageApi/ResetSmsOtp", {}, () => {
      toast.success(t<string>("2fa/textMessage.textMessageAuthenticatorReset"));

      setTfaData({
        ...tfa,
        smsOtpEnabledDate: false,
        smsOtpEnabled: false,
      });

      eventDisableTfa(TfaTypes.sms);
    });
  };

  const scope = {
    onResetSmsOtp,
    onSendOtpViaSMS,
    handleSubmit,
    register,
    smsOtpEnabled,
    smsOtpEnabledDate,
    phoneUsed,
    renderPath,
    NavLink,
    Icon,
    TFA_PATH,
    t,
    errors,
    loading,
    resetSmsOtpLoading,
  };

  const textMessageModalPreview = templateSearchParam === "text-message-modal";

  useEffect(() => {
    if (textMessageModalPreview) toggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{t<string>("2fa/textMessage.2faTextMessageTitle")}</title>
        <style>{textMessageAuthTemplate?.css}</style>
      </Helmet>

      {!textMessageAuthTemplateLoading && (
        <LiveProvider
          code={textMessageAuthTemplate?.html || "<></>"}
          scope={scope}
        >
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}

      {visible && (
        <TextMessageModal visible={visible} hide={toggle} optObj={optObj} />
      )}
    </>
  );
};
