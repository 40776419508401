import { FC } from "react";
import {
  Divider,
  Paragraph,
  Title,
  FontWeight,
  List,
} from "../../components/terms";
import { TermsContainer } from "../../components/terms/TermsContainer";

export const PrivacyPolicy: FC = () => {
  return (
    <TermsContainer>
      <div id="first-section">
        <Paragraph text="Gjirafa Privacy Policy" mb="mb-2" className="pt-4" />

        <Title
          text="When you use our services, you’re trusting us with your
              information. We understand this is a big responsibility and work
              hard to protect your information and put you in control."
          fontWeight={FontWeight.bold}
        />

        <Paragraph
          text="This Privacy Policy is meant to help you understand what
              information we collect, why we collect it, and how you can update,
              manage, export, and delete your information."
          className="pt-2"
        />
      </div>

      <Divider />

      <div id="second-section">
        <Title
          text="We want you to understand the types of information we collect as
              you use our services"
          className="sm:pt-8"
          fontWeight={FontWeight.medium}
          mb="mb-5"
        />

        <Paragraph
          text="We collect information to provide better services to all our users
              — from figuring out basic stuff like which language you speak, to
              more complex things like which ads you’ll find most useful, the
              people who matter most to you online, or which YouTube videos you
              might like. The information Gjirafa collects, and how that
              information is used, depends on how you use our services and how
              you manage your privacy controls."
        />

        <Paragraph
          text="When you’re not signed in to a Gjirafa Account, we store the
              information we collect with unique identifiers tied to the
              browser, application, or device you’re using. This helps us do
              things like maintain your language preferences across browsing
              sessions."
        />

        <Paragraph
          text="When you’re signed in, we also collect information that we store
              with your Gjirafa Account, which we treat as personal information."
        />
      </div>

      <Divider />

      <div id="third-section">
        <Title
          text="Exporting, removing & deleting your information"
          className="sm:pt-8"
          fontWeight={FontWeight.medium}
          mb="mb-5"
        />

        <Paragraph text="You can export a copy of content in your Google Account if you want to back it up or use it with a service outside of Google." />

        <Paragraph text="You can also request to remove content from specific Google services based on applicable law." />

        <Paragraph text="You can also request to remove content from specific Google services based on applicable law." />

        <p className="text-sm mb-2 sm:mb-5">
          To delete your information, you can:
        </p>

        <List
          options={[
            "Delete your content from specific Gjirafa services",
            "Search for and then delete specific items from your account using My Activity",
            "Delete specific Gjirafa products, including your information associated with those products",
            "Delete your entire Gjirafa Account",
          ]}
        />
      </div>

      <Divider mSpace="mt-6 sm:mt-24 mb-6 sm:mb-10" />
    </TermsContainer>
  );
};

export default PrivacyPolicy;
