import { FC } from "react";
import { useTranslation } from "react-i18next";

import { IframeWrapper } from "../../components";
import { useQuery } from "../../hooks";
import { SUBSCRIPTIONS_PATH } from "../../utils";

export const SubscriptionsList: FC = () => {
  const { t } = useTranslation();
  const search = useQuery();

  let type = search.get("type");

  const pageTitle =
    type === "active"
      ? t("subscriptions.activeSubscriptions")
      : t("subscriptions.expiredSubscriptions");
  return (
    <IframeWrapper
      to={SUBSCRIPTIONS_PATH}
      apiUrlDomain={`subscriptions/${type}`}
      pageTitle={pageTitle}
      pageDescription={
        type === "active"
          ? t("subscriptions.manageActiveSubscriptions")
          : t("subscriptions.manageExpiredSubscriptions")
      }
    />
  );
};
