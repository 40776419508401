import moment from "moment";
// TODO: should replace moment with date-fns
// import { format, } from "date-fns";

export const formatDate = (date: Date) => {
    return moment(
        date
      ).format("Do MMMM, YYYY");
}

export const formatToDefaultDate = (date: Date) => {
  return moment(date).format("MM/DD/YYYY") || null
}

export const convertToDate = (date: string) => {
  return moment(date,  "MM/DD/YYYY").toDate() || null
  }