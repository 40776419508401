import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { PageHeader, PageInfoItem } from "../../components";
import { MainContainer } from "../../containers";
import { useFetch } from "../../hooks";
import {
  featureEnabled,
  SITE_LICENCES_PATH,
  SUBSCRIPTIONS_LIST_PATH,
} from "../../utils";

export const Subscriptions: FC = () => {
  const { t } = useTranslation();
  const { apiCall: getTonosData, response } = useFetch("get");

  useEffect(() => {
    getTonosData("https://client-api.tonos.gjirafa.dev/client/account", {
      headers: {
        AppId: null as any,
        ExternalIframe: true as any,
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isLicenceManager = response?.data?.isLicenseManager;

  return (
    <>
      <Helmet>
        <title>{t<string>("subscriptions.title")}</title>
      </Helmet>

      <MainContainer>
        <PageHeader
          title={t("subscriptions.title")}
          description={t("subscriptions.description")}
          iconUrl="images/subscriptions-icon.svg"
        />

        <div className="mt-14">
          <PageInfoItem
            title={t("subscriptions.activeSubscriptions")}
            description={t("subscriptions.activeSubscriptionsDescription")}
            iconName="active-subscriptions.png"
            to={`${SUBSCRIPTIONS_LIST_PATH}?type=active`}
          />

          <PageInfoItem
            title={t("subscriptions.expiredSubscriptions")}
            description={t("subscriptions.expiredSubscriptionsDescription")}
            iconName="expired-subscriptions.png"
            to={`${SUBSCRIPTIONS_LIST_PATH}?type=expired`}
            lastChild={!isLicenceManager}
          />

          {isLicenceManager && featureEnabled["gifts"] === true && (
            <PageInfoItem
              title={t("subscriptions.siteLicence")}
              description={t("subscriptions.siteLicenceDescription")}
              iconName="site-licence.png"
              to={SITE_LICENCES_PATH}
              lastChild
            />
          )}
        </div>
      </MainContainer>
    </>
  );
};
