import { FC } from "react";

interface IList {
  options: string[];
}

export const List: FC<IList> = ({ options }) => {
  return (
    <ul className="list-disc pl-6">
      {options.map((option, index) => {
        return (
          <li className="text-sm text-neutral leading-9" key={index}>
            {option}
          </li>
        );
      })}
    </ul>
  );
};
