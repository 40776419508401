import { FC, ReactNode } from "react";
import { TermsFooter } from "../components";

interface IMainContainer {
  children: ReactNode;
}

export const MainContainer: FC<IMainContainer> = ({ children }) => {
  return (
    <>
      {/* <div className="flex">
        <Sidebar /> */}

      <section className="py-6 px-4 w-full max-w-4xl mx-auto">
        {children}
      </section>
      {/* </div> */}

      <TermsFooter />
    </>
  );
};
