import i18next from "i18next";

export const validateImage = (file: File, acceptedFileTypes: string, size: number, allowedSize: string) => {
  const maxSize = size; //byte
  const acceptedFileTypesArray = acceptedFileTypes?.split(",").map((item) => {
    return item.trim();
  });
  if (!acceptedFileTypesArray.includes(file.type)) {
    return i18next.t("profile.fileFormatNotSupported");
  } else if (file.size > maxSize) {
    return i18next.t("profile.fileSizeNotSupported")
  } else return "";
};
