import { FC } from "react";
import { cx } from "../../utils";

export enum MessageTypes {
  success = "confirm",
  error = "warning",
}

export interface MessageProps {
  message: string;
  type?: MessageTypes;
  withIcon?: boolean;
  containerClassName?: string;
}

export const Message: FC<MessageProps> = ({
  containerClassName,
  type = MessageTypes.error,
  message,
  withIcon = true,
}) =>
  message ? (
    <div className={cx(["flex items-center", containerClassName])}>
      {withIcon && type && renderSvg(type)}
      <p
        className={cx([
          "text-sm",
          type && `text-${type}`,
          withIcon && type && "pl-1",
        ])}
      >
        {message}
      </p>
    </div>
  ) : null;

const renderSvg = (type?: MessageTypes) => {
  switch (type) {
    case MessageTypes.success:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99994 3.06667C5.27533 3.06667 3.0666 5.2754 3.0666 8C3.0666 10.7246 5.27533 12.9333 7.99994 12.9333C10.7245 12.9333 12.9333 10.7246 12.9333 8C12.9333 5.2754 10.7245 3.06667 7.99994 3.06667ZM2.2666 8C2.2666 4.83357 4.8335 2.26667 7.99994 2.26667C11.1664 2.26667 13.7333 4.83357 13.7333 8C13.7333 11.1664 11.1664 13.7333 7.99994 13.7333C4.8335 13.7333 2.2666 11.1664 2.2666 8Z"
            fill="#36B37E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5685 6.19335C10.7247 6.34956 10.7247 6.60282 10.5685 6.75903L7.568 9.75951C7.38576 9.94176 7.09028 9.94176 6.90804 9.75951L5.43137 8.28284C5.27516 8.12663 5.27516 7.87337 5.43137 7.71716C5.58758 7.56095 5.84084 7.56095 5.99705 7.71716L7.23802 8.95812L10.0028 6.19335C10.159 6.03714 10.4123 6.03714 10.5685 6.19335Z"
            fill="#36B37E"
          />
        </svg>
      );
    case MessageTypes.error:
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.2666 8C2.2666 4.83357 4.8335 2.26667 7.99994 2.26667C11.1664 2.26667 13.7333 4.83357 13.7333 8C13.7333 11.1664 11.1664 13.7333 7.99994 13.7333C4.8335 13.7333 2.2666 11.1664 2.2666 8ZM7.99994 3.06667C5.27533 3.06667 3.0666 5.2754 3.0666 8C3.0666 10.7246 5.27533 12.9333 7.99994 12.9333C10.7245 12.9333 12.9333 10.7246 12.9333 8C12.9333 5.2754 10.7245 3.06667 7.99994 3.06667ZM7.99994 4.93333C8.22085 4.93333 8.39994 5.11242 8.39994 5.33333V8.38095C8.39994 8.60187 8.22085 8.78095 7.99994 8.78095C7.77902 8.78095 7.59994 8.60187 7.59994 8.38095V5.33333C7.59994 5.11242 7.77902 4.93333 7.99994 4.93333ZM7.99994 9.88572C8.22085 9.88572 8.39994 10.0648 8.39994 10.2857V10.2933C8.39994 10.5142 8.22085 10.6933 7.99994 10.6933C7.77902 10.6933 7.59994 10.5142 7.59994 10.2933V10.2857C7.59994 10.0648 7.77902 9.88572 7.99994 9.88572Z"
            fill="#E34850"
          />
        </svg>
      );
    default:
      return null;
  }
};
