import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { LiveError, LivePreview, LiveProvider } from "react-live";
import { useFetch, usePreviewMode } from "../../hooks";
import { colors, cx, templatesApiUrl } from "../../utils";
import { Dropdown } from "../dropdown";
import { Dots } from "../icons";

export const ApplicationsDropdown = () => {
  const { isPreviewMode, templateSearchParam } = usePreviewMode();

  const { apiCall: GetApplications, response: applications } = useFetch("get");
  const {
    apiCall: getApplicationTemplate,
    response: applicationTemplate,
    loading: applicationTemplateLoading,
  } = useFetch("get");

  useEffect(() => {
    GetApplications("ManageApi/GetApplicationsToDisplay");
    getApplicationTemplate(
      templatesApiUrl("applicationsdropdown", isPreviewMode)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (applications?.length < 1) {
    return <></>;
  }

  const applicationsDropdownPreview =
    templateSearchParam === "applicationsdropdown";

  const scope = {
    applications,
    Dropdown,
    cx,
    Dots,
    colors,
    applicationsDropdownPreview,
  };

  return (
    <>
      <Helmet>
        <style>{applicationTemplate?.css}</style>
      </Helmet>
      {applicationTemplateLoading && (
        <div className="flex items-center justify-center py-12 w-full">
          <div className="loader"></div>
        </div>
      )}
      {!applicationTemplateLoading && (
        <LiveProvider code={applicationTemplate?.html || "<></>"} scope={scope}>
          <LivePreview />
          <LiveError />
        </LiveProvider>
      )}
    </>
  );
};
