
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { axios } from "./axios";

export const getLanguage = () => i18n.language || window.localStorage.i18nextLng
export let loadedTranslated = false;

  const loadResources = async (locale:string)=> {
    if(locale !=="dev")
    return await axios.get(`Localization/LanguageCode?code=${locale}`)
      .then((response) => {
        
         return response.data 
        })
      .catch((error) => { console.log(error); });
  }
  
 export const backendOptions = {
    loadPath: '{{lng}}', 
    request: (options:any, url:any, payload:any, callback:any) => {
        try {
          loadResources(url).then((response) => {
           callback(null, {
             data: response,
             status: 200, 
           });
         });
       } catch (e) {
         console.error(e);
         callback(null, {
           status: 500,
         });
       }
    },
  };

  i18n
  .use(initReactI18next) 
  .use(Backend)
  .init({
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
    useSuspense: false,

  }, 
  debug: false,
  load:"languageOnly",
  backend: backendOptions,
}, () => {
  loadedTranslated = true;
});

export default i18n;
