export const colors = [
    {
      textColor: "#2680EB",
      bgColor: "#F0F6FF",
    },
    {
      textColor: "#339E81",
      bgColor: "#E9FFF9",
    },
    {
      textColor: "#0491B1",
      bgColor: "#E0F9FF",
    },
    {
      textColor: "#D95E42",
      bgColor: "#FFEDE9",
    },
    {
      textColor: "#1F903F",
      bgColor: "#E7FFED",
    },
    {
      textColor: "#D356B7",
      bgColor: "#FFECFB",
    },
    {
      textColor: "#7761FF",
      bgColor: "#EEEBFF",
    },
    {
      textColor: "#B764FF",
      bgColor: "#F4E8FF",
    },
    {
      textColor: "#6A8EC5",
      bgColor: "#F0F6FF",
    },
    {
      textColor: "#5A991F",
      bgColor: "#F2FFE6",
    },
    {
      textColor: "#F9AB00",
      bgColor: "#FFF5E0",
    },
  ];